import React from "react"
import { graphql } from "gatsby"

import {Container, Row, Col} from 'reactstrap'

import Layout from '../components/layout'
import Cloud from '../components/cloud'

export default ({ data }) => (
    <Layout title="Services">
        <Cloud title="UNSERE DIENSTLEISTUNGEN"/>

        <Container className="bg-white service-overview">
            <Row>
                <Col md="4">
                    <div className="overview-item text-center">
                        <i className="fas fa-shopping-cart"></i>
                        <h3>E-Commerce Shop System</h3>
                        <p>Wir planen, designen und programmieren Ihren eigenen Online Shop.</p>
                    </div>
                </Col>
                <Col md="4">
                    <div className="overview-item text-center">
                        <i className="fas fa-cloud"></i>
                        <h3>Cloud Strategy</h3>
                        <p>Cloud Hosting Setup</p>
                        <p>Planung einer skalierbaren Server Infrastruktur</p>
                    </div>
                </Col>
                <Col md="4">
                    <div className="overview-item text-center">
                        <i className="fab fa-connectdevelop"></i>
                        <h3>Software Consulting</h3>
                        <p>Individuelle Beratung im Bereich Software Design und Architektur</p>
                        <p>Umfassendes Know-how in dem Bereich Web-Services</p>
                    </div>
                </Col>
            </Row>
        </Container>

        <div className="bg-dark-light">
            <Container className="service-list">
                {data.allMarkdownRemark.edges.map(({node}) => (
                    <Row className="service" key={node.id}>
                        <Col md="4">
                            <img className="img-fluid" src={node.frontmatter.image} alt={node.frontmatter.title} />
                        </Col>
                        <Col md="8">
                            <h3 className="md-margin-top-20">
                                {node.frontmatter.title}
                            </h3>
                            <div dangerouslySetInnerHTML={{ __html: node.html }} />
                        </Col>
                    </Row>
                ))}
            </Container>
        </div>


    </Layout>
)

export const query = graphql`
  query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___sort], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "service" } } }
      ) {
        totalCount
        edges {
          node {
            html,
            frontmatter {
              title,
              image
            }
          }
        }
      }
    }
`